<template>
  <div class="ui-parent-section">
    <UISection>
      <template #title>
        <div>Диалоги</div>
      </template>

      <div>
        <el-button @click="showDialog(true)"> открыть диолог </el-button>

        <Dialog name="test-dialog">
          <template #title>
            <div>Диалог</div>
          </template>

          <p class="m-b-10">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Accusantium adipisci, debitis in inventore labore magnam magni nihil
            quod, ratione, repellat sed similique tempore vitae! Aspernatur
            cupiditate dolor eius, eligendi nemo quidem quis ratione repudiandae
            vitae? Accusamus, aspernatur assumenda consequuntur cumque debitis
            doloremque doloribus eius enim esse, fugiat harum illo, inventore
            laboriosam molestias nemo odio perferendis ratione suscipit tempore
            ullam ut voluptatem voluptatum? Animi assumenda, beatae, commodi
            consequuntur corporis delectus dignissimos doloremque dolores eius
            est excepturi facere facilis harum illum impedit in laborum non
            nulla quisquam sequi totam ut veritatis voluptatem voluptates
            voluptatum. Ab accusamus ad aspernatur autem beatae eaque eligendi
            enim eos fugiat id, illum iste iure, magnam non odio odit optio
            sapiente sed sit tempore ullam unde voluptates voluptatum?
            Accusantium amet asperiores atque aut consequatur doloremque, ea
            magni, mollitia nesciunt quas repellendus, saepe sequi sunt?
            Aspernatur debitis maxime molestiae nihil odit quasi saepe sed sit!
            Delectus dignissimos fuga fugiat ipsa, iure iusto labore molestias
            nisi perspiciatis possimus quae quas quia quidem rem, repellendus
            sint sit ut veritatis. A accusamus ad alias animi autem blanditiis
            culpa cumque dolorum ducimus excepturi expedita fuga illo illum
            inventore magni molestias mollitia necessitatibus numquam pariatur
            qui quidem quos reiciendis, sint, sit vel veritatis vero!
          </p>

          <div class="add-rule-culture__btns df jc-fe">
            <el-button
              class="w-175 w-ts-100p"
              type="info"
              @click="showDialog(false)"
            >
              Отменить
            </el-button>

            <el-button
              class="w-175 w-ts-100p m-l-ts-0"
              type="warning"
              @click="showDialog(false)"
            >
              Окей
            </el-button>
          </div>
        </Dialog>
      </div>
    </UISection>
  </div>
</template>

<script>
import Dialog from '@/UI/dialog/Dialog'
import UISection from '@/views/ui/components/UISection'
export default {
  name: 'Dialogs',
  components: { Dialog, UISection },
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    showDialog(visible) {
      this.setDialog({ name: 'test-dialog', visible })
    },
  },
}
</script>

<style lang="sass">
.color-box
  width: 180px
  background: #FFFFFF
  border: 1px solid #DCDCDC
  padding: 8px
  border-radius: 8px

  &__wrap
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-wrap: wrap
    gap: 10px

  &__color
    width: 100%
    padding-top: 100%
    background: darkred
    border-radius: 8px
    margin-bottom: 8px
    border: 1px solid #DCDCDC
</style>
