var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Диалоги")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.showDialog(true)
                    },
                  },
                },
                [_vm._v(" открыть диолог ")]
              ),
              _c(
                "Dialog",
                {
                  attrs: { name: "test-dialog" },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [_c("div", [_vm._v("Диалог")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("p", { staticClass: "m-b-10" }, [
                    _vm._v(
                      " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium adipisci, debitis in inventore labore magnam magni nihil quod, ratione, repellat sed similique tempore vitae! Aspernatur cupiditate dolor eius, eligendi nemo quidem quis ratione repudiandae vitae? Accusamus, aspernatur assumenda consequuntur cumque debitis doloremque doloribus eius enim esse, fugiat harum illo, inventore laboriosam molestias nemo odio perferendis ratione suscipit tempore ullam ut voluptatem voluptatum? Animi assumenda, beatae, commodi consequuntur corporis delectus dignissimos doloremque dolores eius est excepturi facere facilis harum illum impedit in laborum non nulla quisquam sequi totam ut veritatis voluptatem voluptates voluptatum. Ab accusamus ad aspernatur autem beatae eaque eligendi enim eos fugiat id, illum iste iure, magnam non odio odit optio sapiente sed sit tempore ullam unde voluptates voluptatum? Accusantium amet asperiores atque aut consequatur doloremque, ea magni, mollitia nesciunt quas repellendus, saepe sequi sunt? Aspernatur debitis maxime molestiae nihil odit quasi saepe sed sit! Delectus dignissimos fuga fugiat ipsa, iure iusto labore molestias nisi perspiciatis possimus quae quas quia quidem rem, repellendus sint sit ut veritatis. A accusamus ad alias animi autem blanditiis culpa cumque dolorum ducimus excepturi expedita fuga illo illum inventore magni molestias mollitia necessitatibus numquam pariatur qui quidem quos reiciendis, sint, sit vel veritatis vero! "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "add-rule-culture__btns df jc-fe" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "w-175 w-ts-100p",
                          attrs: { type: "info" },
                          on: {
                            click: function ($event) {
                              return _vm.showDialog(false)
                            },
                          },
                        },
                        [_vm._v(" Отменить ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "w-175 w-ts-100p m-l-ts-0",
                          attrs: { type: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.showDialog(false)
                            },
                          },
                        },
                        [_vm._v(" Окей ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }